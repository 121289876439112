@include govuk-exports("govuk/component/summary-list") {
  .govuk-summary-list {
    @include govuk-font($size: 19);
    @include govuk-text-colour;
    @include govuk-media-query($from: tablet) {
      display: table;
      width: 100%;
      table-layout: fixed; // Required to allow us to wrap words that overflow.
    }
    margin: 0; // Reset default user agent styles
    @include govuk-responsive-margin(6, "bottom");
  }

  .govuk-summary-list__row {
    @include govuk-media-query($until: tablet) {
      margin-bottom: govuk-spacing(3);
      border-bottom: 1px solid $govuk-border-colour;
    }
    @include govuk-media-query($from: tablet) {
      display: table-row;
    }
  }

  .govuk-summary-list__key,
  .govuk-summary-list__value,
  .govuk-summary-list__actions {
    margin: 0; // Reset default user agent styles

    @include govuk-media-query($from: tablet) {
      display: table-cell;
      padding-top: govuk-spacing(2);
      padding-right: govuk-spacing(4);
      padding-bottom: govuk-spacing(2);
      border-bottom: 1px solid $govuk-border-colour;
    }
  }

  .govuk-summary-list__actions {
    margin-bottom: govuk-spacing(3);
    @include govuk-media-query($from: tablet) {
      width: 20%;
      padding-right: 0;
      text-align: right;
    }
  }

  .govuk-summary-list__key,
  .govuk-summary-list__value {
    // Automatic wrapping for unbreakable text (e.g. URLs)
    word-wrap: break-word; // Fallback for older browsers only
    overflow-wrap: break-word;
  }

  .govuk-summary-list__key {
    margin-bottom: govuk-spacing(1);
    @include govuk-typography-weight-bold;
    @include govuk-media-query($from: tablet) {
      width: 30%;
    }
  }

  .govuk-summary-list__value {
    @include govuk-media-query($until: tablet) {
      margin-bottom: govuk-spacing(3);
    }
    @include govuk-media-query($from: tablet) {
      width: 50%;
    }
  }

  // Expand width when value is last column (no action)
  .govuk-summary-list__value:last-child {
    @include govuk-media-query($from: tablet) {
      width: 70%;
    }
  }

  .govuk-summary-list__value > p {
    margin-bottom: govuk-spacing(2);
  }

  .govuk-summary-list__value > :last-child {
    margin-bottom: 0;
  }

  .govuk-summary-list__actions-list {
    width: 100%;
    margin: 0; // Reset default user agent styles
    padding: 0; // Reset default user agent styles
  }

  .govuk-summary-list__actions-list-item {
    display: inline;
    margin-right: govuk-spacing(2);
    padding-right: govuk-spacing(2);
  }

  // In older browsers such as IE8, :last-child is not available,
  // so only show the border divider where it is available.
  .govuk-summary-list__actions-list-item:not(:last-child) {
    border-right: 1px solid $govuk-border-colour;
  }

  .govuk-summary-list__actions-list-item:last-child {
    margin-right: 0;
    padding-right: 0;
    border: 0;
  }

  // No border on entire summary list
  .govuk-summary-list--no-border {
    @include govuk-media-query($until: tablet) {
      .govuk-summary-list__row {
        border: 0;
      }
    }

    @include govuk-media-query($from: tablet) {
      .govuk-summary-list__key,
      .govuk-summary-list__value,
      .govuk-summary-list__actions {
        // Remove 1px border, add 1px height back on
        padding-bottom: govuk-spacing(2) + 1px;
        border: 0;
      }
    }
  }

  // No border on specific rows
  .govuk-summary-list__row--no-border {
    @include govuk-media-query($until: tablet) {
      border: 0;
    }

    @include govuk-media-query($from: tablet) {
      .govuk-summary-list__key,
      .govuk-summary-list__value,
      .govuk-summary-list__actions {
        // Remove 1px border, add 1px height back on
        padding-bottom: govuk-spacing(2) + 1px;
        border: 0;
      }
    }
  }
}

@if not mixin-exists("govuk-exports") {
  @warn "Importing items from the core layer without first importing `base` is deprecated, and will no longer work as of GOV.UK Frontend v4.0.";
}

@import "../base";

@include govuk-exports("govuk/core/section-break") {

  %govuk-section-break {
    margin: 0;
    border: 0;

    // fix double-width section break and forced visible section break
    // when combined with styles from alphagov/elements
    @include govuk-compatibility(govuk_elements) {
      height: 0;
    }
  }

  .govuk-section-break {
    @extend %govuk-section-break;
  }

  // Sizes

  %govuk-section-break--xl {
    @include govuk-responsive-margin(8, "top");
    @include govuk-responsive-margin(8, "bottom");
  }

  .govuk-section-break--xl {
    @extend %govuk-section-break--xl;
  }

  %govuk-section-break--l {
    @include govuk-responsive-margin(6, "top");
    @include govuk-responsive-margin(6, "bottom");
  }

  .govuk-section-break--l {
    @extend %govuk-section-break--l;
  }

  %govuk-section-break--m {
    @include govuk-responsive-margin(4, "top");
    @include govuk-responsive-margin(4, "bottom");
  }

  .govuk-section-break--m {
    @extend %govuk-section-break--m;
  }

  // Visible variant

  %govuk-section-break--visible {
    border-bottom: 1px solid $govuk-border-colour;
  }

  .govuk-section-break--visible {
    @extend %govuk-section-break--visible;
  }
}

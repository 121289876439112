@if not mixin-exists("govuk-exports") {
  @warn "Importing items from the overrides layer without first importing `base` is deprecated, and will no longer work as of GOV.UK Frontend v4.0.";
}

@import "../base";

// stylelint-disable declaration-no-important
@include govuk-exports("govuk/overrides/display") {
  .govuk-\!-display-inline {
    display: inline !important;
  }

  .govuk-\!-display-inline-block {
    display: inline-block !important;
  }

  .govuk-\!-display-block {
    display: block !important;
  }

  .govuk-\!-display-none {
    display: none !important;
  }

  @include govuk-media-query($media-type: print) {
    .govuk-\!-display-none-print {
      display: none !important;
    }
  }
}

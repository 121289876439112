@import "../error-message/index";
@import "../hint/index";
@import "../label/index";

@include govuk-exports("govuk/component/select") {
  .govuk-select {
    @include govuk-font($size: 19, $line-height: 1.25);

    box-sizing: border-box; // should this be global?
    max-width: 100%;
    height: 40px;
    @if $govuk-typography-use-rem {
      height: govuk-px-to-rem(40px);
    }
    padding: govuk-spacing(1); // was 5px 4px 4px - size of it should be adjusted to match other form elements
    border: $govuk-border-width-form-element solid $govuk-input-border-colour;

    &:focus {
      outline: $govuk-focus-width solid $govuk-focus-colour;
      // Ensure outline appears outside of the element
      outline-offset: 0;
      // Double the border by adding its width again. Use `box-shadow` to do
      // this instead of changing `border-width` (which changes element size) and
      // since `outline` is already used for the yellow focus state.
      box-shadow: inset 0 0 0 $govuk-border-width-form-element;

      @include govuk-if-ie8 {
        // IE8 doesn't support `box-shadow` so double the border with
        // `border-width`.
        border-width: $govuk-border-width-form-element * 2;
      }
    }
  }

  .govuk-select option:active,
  .govuk-select option:checked,
  .govuk-select:focus::-ms-value {
    color: govuk-colour("white");
    background-color: govuk-colour("blue");
  }

  .govuk-select--error {
    border: $govuk-border-width-form-element-error solid $govuk-error-colour;

    &:focus {
      border-color: $govuk-input-border-colour;
    }
  }
}

@if not mixin-exists("govuk-exports") {
  @warn "Importing items from the core layer without first importing `base` is deprecated, and will no longer work as of GOV.UK Frontend v4.0.";
}

@import "../base";

@include govuk-exports("govuk/core/template") {

  // Applied to the <html> element
  .govuk-template {
    // Set the overall page background colour to the same colour as used by the
    // footer to give the illusion of a long footer.
    background-color: $govuk-canvas-background-colour;

    // Prevent automatic text sizing, as we already cater for small devices and
    // would like the browser to stay on 100% text zoom by default.
    -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
            text-size-adjust: 100%;

    // Force the scrollbar to always display in IE, to prevent horizontal page
    // jumps as content height changes (e.g. autocomplete results open).
    @include govuk-media-query($media-type: screen) {
      overflow-y: scroll;
    }
  }

  // Applied to the <body> element
  .govuk-template__body {
    // The default margins set by user-agents are not required since we have our
    // own containers.
    margin: 0;
    // Set the overall body of the page back to the typical background colour.
    background-color: $govuk-body-background-colour;
  }
}

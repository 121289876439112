@if not mixin-exists("govuk-exports") {
  @warn "Importing items from the core layer without first importing `base` is deprecated, and will no longer work as of GOV.UK Frontend v4.0.";
}

@import "../base";

@include govuk-exports("govuk/core/links") {

  %govuk-link {
    @include govuk-link-common;
    @include govuk-link-style-default;
    @include govuk-link-print-friendly;
  }

  .govuk-link {
    @extend %govuk-link;
  }

  // Variant classes should always be used in conjunction with the .govuk-link
  // class, so we do not need the common link styles as they will be inherited.

  .govuk-link--muted {
    @include govuk-link-style-muted;
  }

  .govuk-link--text-colour {
    @include govuk-link-style-text;
  }

  .govuk-link--inverse {
    @include govuk-link-style-inverse;
  }

  .govuk-link--no-underline {
    @include govuk-link-style-no-underline;
  }

  .govuk-link--no-visited-state {
    @include govuk-link-style-no-visited-state;
  }
}
